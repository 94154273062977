<template>
  <div class="top-profession-form">
    <p class="title">各専門領域で転職をはじめる</p>
    <div class="links">
      <TopProfessionLink to="cfo" icon="apartment" text="CFO" />
      <TopProfessionLink to="manager" icon="award-star" text="管理部長" />
      <TopProfessionLink to="accounting" icon="savings" text="経理財務" />
      <TopProfessionLink to="audit" icon="search" text="常勤監査役・内部監査" />
      <TopProfessionLink
        to="information_system"
        icon="terminal"
        text="情報システム"
      />
      <TopProfessionLink
        to="cpa"
        icon="calculate"
        text="公認会計士・FAS"
        sub-icon="license"
      />
      <TopProfessionLink
        to="corporate_planning"
        icon="flowsheet"
        text="経営企画"
      />
      <TopProfessionLink to="human_resource" icon="badge" text="人事労務" />
      <TopProfessionLink to="legal" icon="legal" text="法務・弁護士" />
      <TopProfessionLink to="irpr" icon="monitor" text="IR・PR" />
    </div>
  </div>
</template>
